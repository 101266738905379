

import moment from 'moment';
import *as apiRes from './api-res-type';
import *as apiResult from './api-result-type';
let uuid = 0
const getId = () => {
    uuid++
    return `${uuid}`
}

const getBoolean = () => {
    return Boolean(Math.round(Math.random() * 1))
}


const textZhCn = '油麻地美食向來選擇甚多，沖繩漁民食堂主打的丼物及日本製讚岐烏冬是招牌名物，定價也非常親民，最平$48起就可以吃得到，超抵！ 如日本鰹魚花蟹籽紫菜溫泉蛋飯、照燒雞扒溫泉蛋飯、唐揚炸雞溫泉蛋飯及吉列豬扒溫泉蛋飯等，若然想配吃日本製讚岐烏冬只需加$10就可，彈性十足'
const textZhTw = '油麻地美食向來選擇甚多，沖繩漁民食堂主打的丼物及日本製讚岐烏冬是招牌名物，定價也非常親民，最平$48起就可以吃得到，超抵！ 如日本鰹魚花蟹籽紫菜溫泉蛋飯、照燒雞扒溫泉蛋飯、唐揚炸雞溫泉蛋飯及吉列豬扒溫泉蛋飯等，若然想配吃日本製讚岐烏冬只需加$10就可，彈性十足'
const texten = `Yau Ma Tei has always had a lot of choices for food. The well food featured in the Okinawa fishermen's canteen and Zanqi udong made in Japan are famous brands, and the price is also very close to the people. You can eat it from $48, which is more than enough! Such as Japanese skate, crab seed, seaweed, hot spring egg, roasted chicken, hot spring egg, Tangyang fried chicken, hot spring egg, Gillette, pork chop, hot spring, egg, rice, etc. if you want to eat Japanese Zanqi, Udon can only add $10.`

const imageUrl = 'https://img2.baidu.com/it/u=2247826721,2592659669&fm=26&fmt=auto'

/**
 * 随机获取 10～20 之间的一个长度：
 * @param start  10
 * @param end  20
 */
const getText = (start: number, end: number) => {
    return {
        'zh-tw': textZhTw.slice(0, Math.round(Math.random() * (end - start) + start)),
        'zh-cn': textZhCn.slice(0, Math.round(Math.random() * (end - start) + start)),
        'en': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
    }
}
const getTextAndDefault = (start: number, end: number) => {
    return {
        'zh-tw': textZhTw.slice(0, Math.round(Math.random() * (end - start) + start)),
        'zh-cn': textZhCn.slice(0, Math.round(Math.random() * (end - start) + start)),
        'en': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
        'default': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
    }
}

export const timeoutData = async<T>(data: T): Promise<T> => {
    return await new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(data)
        }, 200);
    })
}


export const PostAuthVerifyResultData = async (start?: number | undefined, end?: number | undefined): Promise<apiResult.PostAuthVerifyResult> => {


    return await timeoutData<apiResult.PostAuthVerifyResult>({
        kind: 'ok',
        data: {
            id: getId(),
            locale: 'zh-tw',
        }
    })
}



export const GetRedemptionRecordListResultData = async (start?: number | undefined, end?: number | undefined): Promise<apiResult.GetRedemptionRecordListResult> => {

    const totalCount = 34

    const redemptionRecordProducts: apiRes.GetRedemptionRecordRes['redemptionRecordProducts'] =
        [...Array((Math.round(Math.random() * (10 - 2) + 2)))]
            .map((item, index) => {
                return {
                    id: getId(),
                    redemptionRecordId: getId(),
                    productId: getId(),
                    productName: getText(5, 30)['zh-tw'],
                    unitCoin: index,
                    amount: 30 + index,
                    createdAt: moment().toISOString(),
                    updatedAt: moment().toISOString(),
                }
            })

    const data: apiRes.GetRedemptionRecordRes[] = [...Array(totalCount)]
        .map((item, index) => {

            return {
                id: getId(),
                shopId: getId(),
                userId: getId(),
                mainFamilyNumber: `mainFamilyNumber${index}`,
                totalCoin: 22,
                receiptNumber: `receiptNumber${index}`,
                createdAt: moment().toISOString(),
                updatedAt: moment().toISOString(),
                redemptionRecordProducts,
            }
        })

    return await timeoutData<apiResult.GetRedemptionRecordListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start, end && end ? end + end : undefined),
            count: totalCount,
        }
    })
}


export const GetUserCoinDateListResultData = async (start?: number | undefined, end?: number | undefined): Promise<apiResult.GetUserCoinDateListResult> => {
    const totalCount = 34
    const data: apiRes.GetUserCoinDateRes[] = [...Array(totalCount)]
        .map((item, index) => {

            return {
                id: getId(),
                userId: getId(),
                mainFamilyNumber: `mainFamilyNumber${index}`,
                expiredAt: moment().toISOString(),
                amount: -5 + index,
                originalAmount: 20 + index,
                createdAt: moment().toISOString(),
                updatedAt: moment().toISOString(),
            }
        })

    return await timeoutData<apiResult.GetUserCoinDateListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start, end && end ? end + end : undefined),
            count: totalCount,
        }
    })
}



export const GetShopListResultData = async (start?: number | undefined, end?: number | undefined): Promise<apiResult.GetShopListResult> => {

    const totalCount = 34
    const data: apiRes.GetShopRes[] = [...Array(totalCount)]
        .map((item, index) => {

            return {
                id: getId(),
                name: getText(4, 15),
                shopNo: getText(4, 15)['zh-tw'],
                remark: getText(4, 200)['zh-tw'],
                regionId: getId(),
                address: getText(20, 60)['zh-tw'],
                phone: '2123345566',
                isVisible: getBoolean(),
                openHour: getText(20, 60)['zh-tw'],
                createdAt: moment().toISOString(),
                updatedAt: moment().toISOString(),
            }
        })

    return await timeoutData<apiResult.GetShopListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start, end && end ? end + end : undefined),
            count: totalCount,
        }
    })
}



export const GetRegionListResultData = async (start?: number | undefined, end?: number | undefined): Promise<apiResult.GetRegionListResult> => {

    const totalCount = 34
    const data: apiRes.GetRegionRes[] = [...Array(totalCount)]
        .map((item, index) => {

            return {
                id: getId(),
                name: getText(4, 15),
                shopNo: getText(4, 15)['zh-tw'],
                remark: getText(4, 200)['zh-tw'],
                regionId: getId(),
                address: getText(20, 60)['zh-tw'],
                phone: '2123345566',
                isVisible: getBoolean(),
                openHour: getText(20, 60)['zh-tw'],
                createdAt: moment().toISOString(),
                updatedAt: moment().toISOString(),
            }
        })

    return await timeoutData<apiResult.GetRegionListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start, end && end ? end + end : undefined),
            count: totalCount,
        }
    })
}
