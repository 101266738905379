import { memo, useEffect, useState } from "react";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { MyText } from "../../components/MyText";
import *as imgs from "../../imgs";
import { I18n } from "react-redux-i18n";




const TextDataZhTw: string[] = [
    '',
    '米仔站－ 鳳德店',
    '',
    '地址：香港九龍黃大仙區鑽石山鳳德道111號 銀鳳樓2／F N241及 N242號店舖',
    '',
    '服務日期：星期一至星期六 （星期日及公衆假期休息）',
    '',
    '服務時間：上午10時至下午7時 （最後入場時間 下午6時45分）',
    '',
    'Whatsapp 號碼：5726 7563',

]

const TextDataZhCn: string[] = [
    '',
    '米仔站－凤德店',
    '',
    '地址：香港九龙黄大仙区钻石山凤德道111号银凤楼2／F N241及N242号店铺',
    '',
    '服务日期：星期一至星期六（星期日及公众假期休息）',
    '',
    '服务时间：上午10时至下午7时（最后入场时间下午6时45分）',
    '',
    'Whatsapp号码：5726 7563',
]


const TextDataEn: string[] = [
    '',
    'Rice Boy Station- Fung Tak Shop',
    '',
    '',
    'Address: Shop Nos. N241 & N242, 2/F, Ngan Fung House, Fung Tak Estate, 111 Fung Tak Road, Diamond Hill, Wong Tai Sin, Kowloon, Hong Kong',
    '',
    '',
    'Service Dates: Monday to Saturday (Close on Sunday and Public Holiday)',
    '',
    '',
    'Service Hours: 10am to 7pm (Last Entry 6:45pm)',
    '',
    '',
    'Whatsapp No.：5726 7563',
]







const TextData = {
    'zh-tw': TextDataZhTw,
    'zh-cn': TextDataZhCn,
    'en': TextDataEn,
}


/**
 * 米仔币
 */
export const MiziCoinInfo = memo(() => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)

    useEffect(() => {
        (async () => {

        })()
    }, [])


    return (
        <div
            style={{
                flex: 1,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                paddingBottom: 80,
            }}
        >
            <div>
                <div style={{
                    width: '100%',
                    height: 180,
                    background: '#ddd',
                }}></div></div>

            <MyText
                type='a2'
                style={{
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 5,
                    marginBottom: 8,
                    fontWeight: '600',
                    color: color.mainB,
                }}

            >
                {I18n.t('MiziCoinInfo__sponsor')}
            </MyText>

            <img
                src={imgs.wsLogo}
                style={{
                    width: '70%',
                    marginLeft: 20,
                    marginBottom: 8,
                }}
            />

            {TextData[config.language].map((text, index) => {
                return (
                    <MyText
                        type='a1'
                        key={index}
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            marginTop: 5,
                            color: color.black_2,
                            fontWeight: '500',
                        }}
                    >
                        {text}
                    </MyText>
                )
            })}
        </div>
    )
})
